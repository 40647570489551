import React from 'react';
import Header from './components/Header';
import Hero from './components/Hero';
import Section2 from './components/Section2'; // Update the import to Section2
import FeatureCarousel from './components/FeatureCarousel';
import WhySection from './components/WhySection';
import FeatureDescription from './components/FeatureDescription'; 
import BenefitSection from './components/BenefitSection';
import CommunitySection from './components/CommunitySection'; 
import ContactSection from './components/ContactSection'; 
import Footer from './components/Footer'; 

function App() {
  return (
    <div>
      <Header />
      <Hero />
      <Section2 />
      <FeatureCarousel />
      <WhySection />
      <FeatureDescription />
      <BenefitSection />
      <CommunitySection />
      <ContactSection />
      <Footer />

    </div>
  );
}

export default App;

