import React from 'react';
import './Hero.css';
import heroImage from '../assets/Main.svg'; // Import the image

function Hero() {
  return (
    <section className="hero">
      <div className="container">
        <div className="hero-text">
          <h1 className="hero-heading">
            Connecting Healthcare,<br /> Empowering Professionals
          </h1>
          <p className="hero-subheading">
            A comprehensive platform for healthcare professionals to network, exchange knowledge, search for jobs, and stay up to date with industry news.
          </p>
        </div>
        <div className="hero-image">
          <img src={heroImage} alt="Hero Graphic" /> {/* Use the imported image */}
        </div>
      </div>
    </section>
  );
}

export default Hero;
